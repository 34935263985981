import { strings } from '~utils/strings';
import { IPolicyMapping } from '~views/organization/policy/policyMapping/PolicyMapping.types.react';

export type Organization = {
  CreatedBy: string;
  CreatedOn: string;
  DeletedAt: null | string;
  GithubEnterpriseBaseURL: string;
  HasReceivedDataFromAgent: boolean;
  Name: string;
  SSOStrict: boolean;
  Tier: number;
  TrialExpiresAt: null | string;
  PolicyStrategy: null | string;
};

export type Cluster = {
  AdmissionSettings: AdmissionSettings;
  AgentChartVersion: string;
  AgentConfiguration?: string;
  AuthToken: string;
  CreatedOn: string;
  DeletedAt?: string;
  Name: string;
  Organization: string;
  Status: string;
  Stats?: ClusterStats;
  Reports: ReportStatus[];
};

export type ClusterStats = {
  NamespacesCount: number;
  NodesCount: number;
  PodsCount: number;
  WorkloadsCount: number;
  Version: string;
};

export type ClusterSummary = {
  AgentChartTargetVersion: string;
  ChartVersionOK: boolean;
  Cluster: Cluster;
  LastUpdated: string;
  SetupNeeded: boolean;
  Summary: {
    Namespaces: number;
    Nodes: number;
    Pods: number;
    Version: string;
    Workloads: number;
  };
  Timestamp: string;
};

export type CodeScanSummary = {
  CriticalActionItems: number;
  DangerActionItems: number;
  HighActionItems: number;
  LowActionItems: number;
  MediumActionItems: number;
  NoneActionItems: number;
  ScanDate: string;
  WarningActionItems: number;
  EligibleAutoFixCount?: number;
};

export type Repository = {
  ID: number;
  InstallationID: number;
  FirstScan?: CodeScanSummary;
  LastScan?: CodeScanSummary;
  Name: string;
  Organization: string;
  AutoScan?: boolean;
  Tickets?: boolean;
  Branches?: string[];
};

export type RepositoryLastScan =
  | 'CriticalActionItems'
  | 'DangerActionItems'
  | 'HighActionItems'
  | 'LowActionItems'
  | 'MediumActionItems'
  | 'NoneActionItems'
  | 'ScanDate'
  | 'WarningActionItems';

export type Report = {
  Cluster: string;
  Offline: boolean;
  ReportType: string;
  UpdateTime: string;
};

export type Membership = {
  CreatedOn: string;
  Email: string;
  IsOwner: boolean;
  Organization: string;
  FirstName?: string;
  LastName?: string;
  Teams?: number[];
};

export interface ResponseError extends Error {
  status?: number;
}

export interface ClusterHealths {
  Current: ClusterHealth;
  Previous: ClusterHealth;
  Clusters: ClusterHealthItem[];
}

export interface AdmissionSettings {
  opaEnabled: boolean;
  passiveMode: boolean;
  plutoEnabled: boolean;
  polarisEnabled: boolean;
}

export interface ReportStatus {
  DisplayName: string;
  Enabled: boolean;
  Name: string;
  LastSeen?: string;
}

export interface ClusterHealth {
  HealthScore: number;
  Passing: number;
  Warning: number;
  Danger: number;
  Critical: number;
  High: number;
  Medium: number;
  Low: number;
  None: number;
}

export interface ClusterHealthItem extends ClusterHealth {
  Name: string;
}

export interface CurrentUser {
  AgreementVersion: number;
  Company: string;
  Confirmed: boolean;
  CreatedOn: string;
  Email: string;
  EmailDigest: boolean;
  FirstName: string;
  IsSuperAdmin: boolean;
  CanCreateOrg: boolean;
  JobTitle: string;
  LastName: string;
  Referral: string;
  Source: string;
  Country: Country;
  CallbackUrl: string;
  AuthorizationCode: string;
}

export enum SortStatus {
  None = 'none',
  Ascending = 'ascending',
  Descending = 'descending',
}

export interface Country {
  Alpha2: string;
  Alpha3: string;
  Name: string;
}

export interface OrganizationTierAccess {
  APIAuth: boolean;
  ActionItemAdvancedFeatures: boolean;
  AddonsTab: boolean;
  AdmissionController: boolean;
  AnnouncementsTab: boolean;
  ClusterCost: boolean;
  CompareTab: boolean;
  ContinuousIntegration: boolean;
  DatadogIntegration: boolean;
  Metrics: boolean;
  PagerDuty: boolean;
  RBACTab: boolean;
  SOC2: boolean;
  Slack: boolean;
  TeamManagement: boolean;
  WorkloadsTab: boolean;
}

export enum Severity {
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  None = 'none',
}

type RepoScanJob = {
  commitHash: string;
  createdAt: string;
  details: string;
  finishedAt: string;
  id: number;
  jobLogs: string;
  ref: string;
  startedAt: string;
  status: string;
};

export interface CodeScan {
  ActionItems: CodeScanActionItem[];
  BaseHash: string;
  BranchName: string;
  CommitHash: string;
  CommitMessage: string;
  CreatedAt: string;
  ID: number;
  Pass: boolean;
  Repository: CodeScanRepository;
  RepositoryID: number;
  Resources: CodeScanK8sResource[];
  RepoScanJob?: RepoScanJob;
}

export interface CodeScanActionItem {
  ID: number;
  ReportType: string;
  EventType: string;
  ResourceID: number;
  Resource: CodeScanK8sResource;
  Title: string;
  Description: string;
  Remediation: string;
  Severity: number;
  Category: string;
  Resolution: string;
}

export interface CodeScanRepository {
  ID: number;
  Name: string;
  Organization: string;
  InstallationID: number;
}

export interface CodeScanK8sResource {
  ID: number;
  Name: string;
  Namespace: string;
  Kind: string;
  Filename: string;
  HelmName: string;
  ContainerName: string;
}

export interface Bot {
  AuthToken: string;
  CreatedAt: string;
  ID: number;
  Name: string;
  Organization: string;
  Role: string;
}

export interface RepoSeverity {
  nCritical: number;
  nHigh: number;
  nMedium: number;
}

export interface EventKeyMap {
  key: string;
  process: () => any;
}

export interface EventMap {
  name: keyof WindowEventMap;
  keyMap: EventKeyMap[];
}

export interface EventCallback {
  [name: string]: {
    callback: EventListener;
  };
}

export type OptionType = {
  value: string;
  label: string;
};

export type JiraUser = {
  accountId: string;
  displayName: string;
  emailAddress: string;
};

export type ConfigReportOptions = {
  enabled: boolean;
  schedule?: string | null;
  timeout?: number | null;
  installPrometheus: boolean;
  address;
  installVPA;
  defaultToOrg;
  privateImages;
};

export type ConfigReport = {
  Cluster: string;
  Options: ConfigReportOptions;
  Organization: string;
  ReportType: string;
};

export type ReportsStatus = {
  DisplayName: string;
  Enabled: boolean;
  LastSeen?: string;
  Name: string;
};

export interface IStore {
  dispatch: (action: string, data: unknown) => void;
  commit: (action: string, data: unknown) => void;
  getters: {
    clusters: Cluster[];
    cluster: Cluster;
    organization: Organization;
    memberships: Membership[];
    membership: Membership;
    user: CurrentUser;
    repository: Repository;
    repositories: Repository[];
    clusterSummary: ClusterSummary;
    isOrgOwner: boolean;
    organizationTierAccess: OrganizationTierAccess;
    strictSAMLOrgs: string[];
    installedReports: string[];
    uninstalledReports: string[];
    installingReports: string[];
    uninstallingReports: string[];
    neverInstalledReports: string[];
    reportsConfigs: Record<string, ConfigReport>;
    reportsStatus: Record<string, ReportsStatus>;
    isAddAppGroupModalShown?: boolean;
  };
}

export interface IRoute {
  name?: string;
  path?: string;
  query?: Record<string, any>;
  href?: string;
  meta?: Record<string, any>;
  params?: Record<string, any>;
  fullPath?: string;
}

export interface IRouter {
  push: (routeInfo: Pick<IRoute, 'path' | 'query' | 'name' | 'params'>) => any;
  resolve: (routeInfo: IRoute) => any;
  replace: (routeInfo: IRoute) => any;
  currentRoute?: {
    name?: string | '';
  };
}

export const CommonLabels = {
  Clear: 'Clear',
  Empty: '',
  False: 'false',
  NoData: 'No Data',
  True: 'true',
  Cancel: 'Cancel',
  CreateTickets: 'Create Tickets',
  CreateTicketsPerImage: 'Create tickets per image',
  CreateTicketsPerImageSingleImage: 'Create ticket per image',
  CreateTicketsPerActionItem: 'Create tickets per action item',
  ViewImageTicket: 'View Image Ticket',
  UpdateAssignee: 'Update Assignee',
  UpdateResolution: 'Update Resolution',
  Hash: '#',
  CreateTicketsConfirmTitle: 'Create Tickets',
  AssignItemsConfirmTitle: 'Assign Action Items',
  ResolveItemsConfirmTitle: 'Resolve Action Items',
  CreateTicketsConfirmContent: "You're about to create up to # ticket(s).",
  CreateTicketsMultipleConfirmContent:
    'You can either create one ticket per image(#1) or one ticket per action item(#2).',
  CreateTicketsMultipleConfirmContentSingleImageTicket:
    'You can either create one ticket per image or one ticket per action item(#).',
  AssignItemsConfirmContent: "You're about to assign # Action Item(s).",
  ResolveItemsConfirmContent: "You're about to update # Action Item(s).",
  CreateTicketsSuccessMessage: '#1 Ticket(s) created, #2 Action Item(s) already had ticket(s)',
  UpdateAssigneeSuccessMessage: 'Updated Assignee for # Action Item(s)',
  UpdateResolveSuccessMessage: 'Updated Resolution for # Action Item(s)',
  Ellipsis: '...',
  Unavailable: 'Unavailable',
  TripleBackticks: '```',
  selectedTimezone: 'selectedTimezone',
} as const;
export interface ChartType {
  value: string;
  count: string;
}

export interface FiltersMapType {
  [key: string]: string | string[] | Record<string, string>[] | null | undefined;
}

export interface RepoActivity {
  ID: number;
  RepositoryID: number;
  Repository: {
    ID: number;
    Name: string;
    Organization: string;
    InstallationID: number;
    AutoScan: true;
    Tickets: true;
  };
  CommitHash: string;
  CommitMessage: string;
  BaseHash: string;
  BranchName: string;
  CreatedAt: string;
  Pass: true;
  Resources: [
    {
      ID: number;
      Name: string;
      Namespace: string;
      Kind: string;
      Filename: string;
      HelmName: string;
      ContainerName: string;
    },
  ];
  ActionItems: [
    {
      ID: number;
      ReportType: string;
      EventType: string;
      ResourceID: number;
      Resource: {
        ID: number;
        Name: string;
        Namespace: string;
        Kind: string;
        Filename: string;
        HelmName: string;
        ContainerName: string;
      };
      Title: string;
      Description: string;
      Remediation: string;
      Severity: number;
      SeverityLabel: string;
      Category: string;
      Resolution: string;
    },
  ];
  FixedActionItems: [
    {
      ID: number;
      ReportType: string;
      EventType: string;
      ResourceID: number;
      Resource: {
        ID: number;
        Name: string;
        Namespace: string;
        Kind: string;
        Filename: string;
        HelmName: string;
        ContainerName: string;
      };
      Title: string;
      Description: string;
      Remediation: string;
      Severity: number;
      SeverityLabel: string;
      Category: string;
      Resolution: string;
    },
  ];
}

export interface RepoActionItemResponse {
  ID: number;
  ReportType: string;
  EventType: string;
  ResourceID: number;
  Resource: {
    ID: number;
    Name: string;
    Namespace: string;
    Kind: string;
    Filename: string;
    HelmName: string;
    ContainerName: string;
  };
  Title: string;
  Description: string;
  Remediation: string;
  Severity: number;
  SeverityLabel: string;
  Category: string;
  Resolution: string;
  HistoricalResolution: string;
  SnoozeUntil?: string;
  Repository: {
    ID: number;
    Name: string;
    Organization: string;
    InstallationID: number;
    AutoScan: true;
    Tickets: true;
  };
  TicketLink?: string;
}

export interface IndeterminateCheckboxProps {
  indeterminate?: boolean;
}

export interface UserFavorite {
  CreatedAt: string;
  Email: string;
  ID: number;
  Organization: string;
  ResourceID: string;
  ResourceType: string;
  UpdatedAt: string;
}

export interface ImageSummary {
  id: number;
  sha: string;
  tag: string;
  operatingSystem: string;
  name: string;
  imageVulnCount: number;
  recommendedImage: string;
  recommendedImageVulnCount: number;
  impactedWorkloads: [
    {
      cluster: string;
      namespace: string;
      name: string;
      container: string;
      kind: string;
    },
  ];
  impactedRepositories: [
    {
      repository: string;
      namespace: string;
      name: string;
      container: string;
      kind: string;
    },
  ];
  lastScanned?: string;
  ticketLink?: string;
  ticketCreatedAt?: string;
  ticketProvider?: string;
}

export interface LatestReport {
  Cluster: string;
  ReportType: string;
  UpdateTime: string;
  Offline: boolean;
}

export type PieData = {
  id: string;
  label: string;
  value: number;
  color: string;
};

export type DonutChartTooltip = {
  arc: any;
  color: string;
  data: PieData;
  formattedValue: string;
  hidden: boolean;
  id: string;
  label: string;
  value: number;
};

export interface ClusterDetails {
  AgentChartTargetVersion: string;
  ChartVersionOK: boolean;
  Cluster: {
    AgentChartVersion: string;
    AgentConfiguration: string | null;
    AuthToken: string;
    CreatedOn: string;
    Name: string;
    Organization: string;
    Status: string;
  };
  LastUpdated: string;
  SetupNeeded: boolean;
  Summary: { Namespaces: number; Nodes: number; Pods: number; Version: string; Workloads: number };
  Timestamp: string;
}

export interface IRule {
  Action: string;
  Cluster: string;
  Context: string;
  CreatedAt: string;
  CreatedBy: string;
  Description: string;
  EventType: string;
  ID: number;
  IsActive: true;
  LastUpdatedAt: string;
  LogsEnabled: true;
  LogsEnabledUntil: string;
  Name: string;
  Organization: string;
  ReportType: string;
  Repository: string;
  RuleOrder: number;
}

export interface IRuleTemplate {
  Name: string;
  Title: string;
  Rule: string;
}

export interface IRulesFormData {
  Action?: string;
  Cluster?: string;
  Context?: string;
  Description?: string;
  LogsEnabled?: boolean;
  Name?: string;
  ReportType?: string;
  Repository?: string;
}

export interface IUserQuotas {
  canCreateFreeOrganization: boolean;
}

export interface IOpenSource {
  organization?: string;
  name?: string;
  license?: {
    code?: string;
    name?: string;
  };
  github?: string;
  isFairwindsOSS?: boolean;
  isFrontend?: boolean;
  version?: string;
}

export interface ILicenseReport {
  department: string;
  relatedTo: string;
  name: string;
  licensePeriod: string;
  material: string;
  licenseType: string;
  link: string;
  remoteVersion: string;
  installedVersion: string;
  definedVersion: string;
  author: string;
}

export interface IViewTicketAffectedItem {
  Cluster: string;
  EventType: string;
  FirstSeen: string;
  Fixed: boolean;
  ID: number;
  IsCustom: boolean;
  LastReportedAt: string;
  Organization: string;
  ReportType: string;
  Resolution: string;
  ResourceKind: string;
  ResourceName: string;
  ResourceNamespace: string;
  Severity: number;
  Tags: null;
  TicketLink: string;
  TicketProvider: string;
  Title: string;
}

export interface IViewTicketResponse {
  AffectedActionItems: IViewTicketAffectedItem[];
  Success: boolean;
  TotalAffected: number;
  TotalSkipped: number;
  TicketCreatedAt?: string;
  TicketLink?: string;
  TicketProvider?: string;
}

export interface IReportEvent {
  Cluster: string;
  Organization: string;
  BaseTime: string;
  UpdateTime: string;
  ReportType: string;
  EventType: string;
  EventDetails: {
    to: string | number;
  };
  Severity: number;
  Message: string;
}

export interface IReportEventSet {
  Timestamp: string;
  Events: IReportEvent[];
  Duplicates: string[];
}
export type AutomatedStandard = {
  label: string;
  codes: string[];
};

export type DateType = {
  start: string;
  end: string;
};

export type Filters = {
  clusters: string[];
  containers: string[];
  kinds: string[];
  namespaces: string[];
  workloads: string[];
};

export type AdvancedOption = OptionType & {
  section: string;
  isDisabled?: boolean;
};

export type SelectedFilters = { [key: string]: AdvancedOption[] };

export type AdvancedFilterOption = { label: string; value: string; section: string };

// type for advanced filters.
export type CustomMenuType = {
  filters: Record<string, string[]>;
  selectedFilters: SelectedFilters;
  selectFilters: (option: AdvancedFilterOption, removedValue: AdvancedFilterOption, action: string) => void;
};

export type MenuListType = { children: AdvancedOption[]; maxHeight: number };

export type ActionItemReportDataObject = {
  fixed: number;
  introduced: number;
  open: number;
  resolved: number;
};

export type ActionItemReportData = {
  cumulative: ActionItemReportDataObject;
  period: ActionItemReportDataObject;
  time: string;
};

export type ActionItemReportChartData = {
  label: string;
  value: number;
};

export type Team = {
  Clusters: string[];
  DisallowedClusters: string[];
  DisallowedNamespaces: string[];
  DisallowedRepositories: string[];
  ID: number;
  Memberships: { Role: string; TeamID: number; UserID: string }[];
  Name: string;
  Namespaces: string[];
  Organization: string;
  Repositories: string[];
};

export const DESELECT_OPTION = 'deselect-option';

export type TeamMembership = {
  CreatedOn: string;
  Email: string;
  FirstName: string;
  IsOwner: boolean;
  LastName: string;
  Organization: string;
  Teams: number[];
  LastLogin: string;
};

export type TabsData = {
  id: string;
  isActive?: boolean;
  label: string;
  onClick: (item: TabsData) => void;
};

export type ActionItemReportLineChartData = {
  id: number;
  data: { x: string; y: number }[];
};

export interface IAppGroupSpec {
  clusters?: string[] | null;
  containers?: string[] | null;
  kinds?: string[] | null;
  labels?: string[] | null;
  names?: string[] | null;
  namespaceLabels?: string[] | null;
  namespaces?: string[] | null;
  repositories?: string[] | null;
}

export interface IAppGroup {
  createdAt: string;
  id: number;
  name: string;
  spec: {
    exclude?: IAppGroupSpec[] | null;
    match?: IAppGroupSpec[] | null;
  };
  type: string;
  updatedAt: string;
}

export const APP_GROUP_SPECS: Record<keyof IAppGroupSpec, string> = {
  names: strings.appGroups.workloadNames,
  clusters: strings.appGroups.clusters,
  repositories: strings.appGroups.repositories,
  namespaces: strings.appGroups.namespaces,
  namespaceLabels: strings.appGroups.namespaceLabels,
  labels: strings.appGroups.labels,
  kinds: strings.appGroups.kinds,
  containers: strings.appGroups.containers,
};

export interface IAppGroupSummary {
  fixedActionItemsCount: number;
  fixedActionItemsUrl: string;
  id: number;
  name: string;
  openActionItemsCount: number;
  openActionItemsUrl: string;
  monthlyBilledCost: number;
  policyMappings: IPolicyMapping[];
}

export type TeamAccessAllSelected = {
  isAllClusterSelected: boolean;
  isAllNamespaceSelected: boolean;
  isAllRepoSelected: boolean;
};

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

export interface SeverityCount {
  nCritical: number;
  nHigh: number;
  nMedium: number;
  nLow: number;
}
